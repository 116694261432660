<script setup>
import { useCssModule, useTemplateRef } from 'vue';
import { useRoute } from 'vue-router';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { addQueryParamsToPath, parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { getPostRegReturnToUrl, getPromoCodeBannerType, navigateReturnTo } from '@@/utils/LoginUtils';
import { useLoginView } from '@@/composables/useLoginView';
import { useLoginStore } from '@@/stores/Login';
import { useUserStore } from '@@/stores/User';

const { $loading, $toast } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const $style = useCssModule();

const loginStore = useLoginStore();
const userStore = useUserStore();

const isSubmitting = ref(false);
const passcode = ref([null, null, null, null]);
const return_to = ref(null);

const inputs = useTemplateRef('inputs');

const { isLoginLinkSentView, isRegisterLinkSentView } = useLoginView();
const isPasscodeValid = computed(() => passcode.value.every((char) => isCharacterValid(char)));

const inputClass = [
  'tw-inline-block',
  'tw-w-9 tw-h-16',
  'tw-mr-2 tw-px-1 tw-py-1.5',
  'tw-border tw-rounded-md',
  'tw-text-2xl tw-font-semibold tw-text-center',
  'focus-within:tw-shadow-lg tw-outline-none',
  $style.input,
];

const findInputIndex = (event) => inputs.value.findIndex((input) => input === event?.target);

const focusInput = (index) => {
  if (!isInputIndexValid(index)) {
    return;
  }

  inputs.value[index].focus();

  // Move cursor to end of value when present
  // @see https://stackoverflow.com/questions/511088/use-javascript-to-place-cursor-at-end-of-text-in-text-input-element
  window.setTimeout(() => {
    const input = inputs.value?.[index];

    if (!input) {
      return;
    }

    input.selectionStart = 10000;
    input.selectionEnd = 10000;
  });
};

/**
 * Focus on previous input when left arrow key pressed
 */
const handleArrowLeft = (event) => {
  const index = findInputIndex(event);
  focusInput(index - 1);
};

/**
 * Focus on next input when right arrow key pressed
 */
const handleArrowRight = (event) => {
  const index = findInputIndex(event);
  focusInput(index + 1);
};

const handleBackspace = (event) => {
  const index = findInputIndex(event);

  if (!isInputIndexValid(index)) {
    return;
  }

  passcode.value[index] = '';

  const { value } = inputs.value[index];

  // If the input does not have a value then focus on previous input.
  if (!value && index > 0) {
    focusInput(index - 1);
    event.preventDefault();
  }
};

const handleButtonClick = (event) => {
  event?.originalEvent?.preventDefault?.();
  handleSubmit();
};

/**
 * When a valid alphanumeric character was pressed then save the value and either focus on the
 * next input or submit the form if the passcode is valid.
 */
const handleKeyup = (event) => {
  const { ctrlKey, metaKey } = event;

  // Use the input value instead of the event key because the event key is not populated
  // correctly in Chrome on Android or Safari on iOS.
  // SEE: https://stackoverflow.com/questions/36753548/keycode-on-android-is-always-229

  const key = event.target.value;

  if (key && key.length === 1 && isCharacterValid(key) && !ctrlKey && !metaKey) {
    const index = findInputIndex(event);

    if (!isInputIndexValid(index)) {
      return;
    }

    const { value } = inputs.value[index];

    // Only focus on the next input if the input value is different from the previous value.
    // This allows the user to press the backspace key to navigate back to the previous input
    // without the keyup event being triggered a second time and navigating forward to the
    // input that just lost focus!

    const shouldFocus = passcode.value[index] !== value;

    passcode.value[index] = value;

    if (shouldFocus) {
      focusInput(index + 1);
    }

    if (isPasscodeValid.value) {
      handleSubmit();
    }
  }
};

const handlePaste = (event) => {
  event.preventDefault();

  const index = findInputIndex(event);
  const newPasscode = event.clipboardData.getData('text');
  populateInputs(newPasscode, index);

  if (isPasscodeValid.value) {
    handleSubmit();
  }
};

const handleSubmit = async () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;

  /* eslint camelcase: off */
  if (!isPasscodeValid.value) {
    $toast.open({ message: 'Please enter a valid 4-digit passcode', type: 'error' });
    isSubmitting.value = false;
    return;
  }

  const handleError = (message) => $toast.open({ message, type: 'error' });

  $loading.start();

  try {
    const passcodeString = passcode.value.join('');
    let response;

    if (isLoginLinkSentView.value) {
      response = await loginStore.makeLoginPasscodeRequest({ passcode: passcodeString });
    }
    else {
      const trial_requested = isRegisterLinkSentView.value;

      response = await loginStore.makeRegisterPasscodeRequest({
        passcode: passcodeString,
        trial_requested,
      });
    }

    const { messages, promo_code, user } = response;

    if (user) {
      if (messages) {
        const type = getPromoCodeBannerType(promo_code);
        const banner = {
          message: messages.join(' '),
          type,
        };
        userStore.setBanner({ banner, saveToSessionStorage: true });
      }

      if (isRegisterLinkSentView.value) {
        return_to.value = addQueryParamsToPath(return_to.value, { source: 'reg_complete' });

        const { gtag } = useGtag();

        if (gtag) {
          gtag('event', 'Web_Trial_Confirmed');
        }
      }

      navigateReturnTo(router, return_to.value);
    }
    else {
      handleError('Unable to login with passcode.');
    }
  }
  catch (e) {
    const { message } = parseOpenMountainApiError(e);
    handleError(message || 'Unable to login with passcode');
  }

  $loading.finish();
  isSubmitting.value = false;
};

const isCharacterValid = (char) => /[0-9]/.test(char);

const isInputIndexValid = (index) => !!inputs.value?.[index];

const populateInputs = (newPasscode, startIndex) => {
  // Filter out invalid characters
  const digits = [...newPasscode].filter((char) => isCharacterValid(char));

  // Assign each digit of the passcode to the corresponding input
  digits.forEach((digit, index) => {
    const inputIndex = startIndex + index;

    if (isInputIndexValid(inputIndex)) {
      inputs.value[inputIndex].value = digit;
      passcode.value[inputIndex] = digit;
    }
  });

  // Focus on input at the end of the pasted value
  const lastInputIndex = Math.min(4, startIndex + digits.length) - 1;
  focusInput(lastInputIndex);
};

onMounted(() => {
  // If a return_to_wasn't provided, send user to where we want based on API config
  return_to.value = getPostRegReturnToUrl(route.query.return_to);
});
</script>

<template>
  <form
    class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center"
    @submit.prevent="handleSubmit"
  >
    <fieldset class="tw-mb-3 lg:tw-mb-0 lg:tw-mr-4 tw-text-center lg:tw-text-left">
      <input
        v-for="n in 4"
        :key="n"
        ref="inputs"
        :class="inputClass"
        inputmode="numeric"
        maxlength="1"
        pattern="[0-9]*"
        type="text"
        @keyup="handleKeyup"
        @keydown.arrow-left="handleArrowLeft"
        @keydown.arrow-right="handleArrowRight"
        @keydown.backspace="handleBackspace"
        @paste="handlePaste"
      >
    </fieldset>
    <Button
      :type="ButtonTypes.primary"
      :disabled="!isPasscodeValid"
      @click="handleButtonClick"
    >
      Verify Code
    </Button>
  </form>
</template>

<style module>
.input {
  background-color: var(--input-background-color);
  border-color: var(--input-border-color);
  color: var(--text-darkest);
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  appearance: none;
}

.input:focus,
.input:focus-within {
  border-color: var(--input-border-color-focus);
}
</style>
